import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CurrentUser } from 'src/app/model/current-user';
import { PriceList } from 'src/app/model/priceList';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  currentSubOpConfig: any;
  currentUser: CurrentUser = new CurrentUser();
  currentMenu: string;
  userData: any;
  tempId;
  roleStatus: any;
  responseIdToEdit: { id: number, page: number };
  subOperatorIdToEdit: { id: number, page: number };

  USERIMAGE = new BehaviorSubject(null);
  USERDATA = new BehaviorSubject(null);

  // userIdPatient = new BehaviorSubject(null);
  // currentuserIdPatient = this.userIdPatient.asObservable();
  focusChange = new Subject();

  constructor(
    private location: Location
  ) { }
  reset() {
    this.currentUser = new CurrentUser();
    this.roleStatus = false;
  }

  changeUserImage(data) {
    this.USERIMAGE.next(data);
  }

  changeUserData(data) {
    this.USERDATA.next(data);
  }

  goBack() {
    this.location.back();
  }

  /**
   * 1 org id in user empty and role not system admin
   *   Show pop up to choose org
   * 2. Role system admin
   *   Go to Home page
   * 3. role not set and org id is empty
   *   Show message user does not have any org
   * 4. Role not set and org id not empty
   *   Show message user does not have role in this org. Contact admin
   * 5. Org id is not empty and role is not "role not set"
   *   Redirect to Home page
   */

  validateUser(data) {
    if (data.roles.includes('SYSTEM_ADMIN')) {
      console.log('Go to Home page');
    } else if (data.roles.includes('ROLE_NOT_SET')) {
      if (!!!data.user.orgId && !!data.org) {
        console.log('Choose Popup');
      } else if (!!!data.user.orgId && !!!data.org) {
        console.log('Show Alert: Please Contact System Admin');
      }
    } else if (!data.roles.includes('ROLE_NOT_SET') && !!data.user.orgId) {
      console.log('Go to Home page');
    }
  }

  jwtTokenConverter(data) {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(data);
    const jwtData = decodedToken;
    jwtData.org = JSON.parse(decodedToken.org);
    jwtData.user = JSON.parse(decodedToken.user);
    return jwtData;
  }
}
