import { ConfirmModalService } from './../confirm-modal/confirm-modal.service';
import { MenuItem } from 'src/app/model/menu-item';
import { SYSTEM_ADMIN, ORG_ADMIN, ORG_USER, MANAGER, SALES_EXECUTIVE, POS_USER } from 'src/app/app.constants';
import { Router } from '@angular/router';
import { GlobalDataService } from '../services/global-data.service';
import { NavigationService } from './navigation.service';

export interface INavigation {
  menus: Array<MenuItem>;
}
export class NavigationModel implements INavigation {
  menus: Array<MenuItem>;
  constructor(
    private router: Router,
    private confirmDialogService: ConfirmModalService,
    private globalDataService: GlobalDataService,
    private navbarService: NavigationService,
  ) {
    this.menus = [
      {
        id: 'home',
        text: 'Home',
        icon: 'assets/images/account.svg',
        route: 'home',
        type: 'collapse',
        active: false,
        roles: [...SYSTEM_ADMIN, ...ORG_ADMIN, ...ORG_USER, ...MANAGER, ...SALES_EXECUTIVE, ...POS_USER],
        submenu: null,
      },
      {
        id: 'orgmanagement',
        text: 'Organization Management',
        icon: 'assets/images/account.svg',
        route: 'organization',
        type: 'collapse',
        active: false,
        roles: [...SYSTEM_ADMIN],
        submenu: null,
      },
      // {
      //   id: 'management',
      //   text: 'Management',
      //   icon: 'assets/images/management.svg',
      //   route: null,
      //   active: false,
      //   roles: [],
      //   type: 'collapse',
      //   submenu: [
      //     {
      //       id: 'user-management',
      //       text: 'User',
      //       icon: 'assets/images/man-user.svg',
      //       route: 'management/user-management',
      //       type: 'item',
      //       active: false,
      //       groupBy: 'management',
      //       roles: [],
      //       submenu: null
      //     },
      //     {
      //       id: 'group-management',
      //       text: 'Group',
      //       icon: 'assets/images/group management.svg',
      //       route: 'management/group-management',
      //       type: 'item',
      //       active: false,
      //       groupBy: 'management',
      //       roles: [],
      //       submenu: null
      //     },
      //     {
      //       id: 'admin-management',
      //       text: 'Admin',
      //       icon: 'assets/images/admin management.svg',
      //       route: 'management/admin-management',
      //       type: 'item',
      //       active: false,
      //       groupBy: 'management',
      //       roles: [],
      //       submenu: null
      //     }
      //   ]
      // },
      {
        id: 'logout',
        text: 'Logout',
        icon: 'assets/images/logout.svg',
        route: null,
        type: 'collapse',
        active: false,
        roles: [...SYSTEM_ADMIN],
        submenu: null,
        function: () => {
          const dialogOpts = {
            title: 'Confirm Dialog',
            message: 'Are you sure you want to logout ?',
            button: { left: 'Cancel', right: 'Confirm' }
          };
          this.confirmDialogService.openDialogModal(dialogOpts)
            .subscribe(res => {
              if (res) {
                const requestBody = JSON.stringify({
                  username: this.globalDataService.currentUser.username
                });

                this.globalDataService.currentUser.token = '';
                sessionStorage.clear();
                this.globalDataService.reset();
                this.router.navigate(['/auth/login']);

              }
            });
          return {};
        },
      },
    ];
  }
}
