import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalDataService } from '../core/services/global-data.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient,
    private gd: GlobalDataService
  ) { }

  getMenu(): Observable<any> {
    return this.http.get(`/api/user/menu/get/master`);
    // return this.http.get(`/api/user/menu/${this.gd.userData.user.org.orgId}`);
  }

  getCommon(): Observable<any> {
    return this.http.get(`/app-service/api/user/get/data/common`);
    // return this.http.get(`/api/user/menu/${this.gd.userData.user.org.orgId}`);
  }
}
