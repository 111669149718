import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalDataService } from '../services/global-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FrameWorkBodyService {

  constructor(
    private http: HttpClient,
    private gd: GlobalDataService
  ) { }

  init(): Observable<any> {
    return this.http.get(`/app-service/api/user/get/data/common`);
  }
}
