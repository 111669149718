import { trigger, transition, style, query as animQuery, group, animateChild, animate, keyframes } from '@angular/animations';

export const fader =
  trigger('routeAnimations', [
    transition('* <=> *', [
      // Set a default  style for enter and leave
      animQuery(':enter, :leave', [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)',
        }),
      ], { optional: true }),
      // Animate the new page in
      animQuery(':enter', [
        animate('600ms ease', style({ opacity: 1, transform: 'scale(1) translateY(0)' })),
      ], { optional: true })
    ]),
  ]);



export const slider =
  trigger('routeAnimations', [
    transition('* => isLeft', slideTo('left')),
    transition('* => isRight', slideTo('right')),
    transition('isRight => *', slideTo('left')),
    transition('isLeft => *', slideTo('right')),
    transition('* => fromRight', slideTo('right')),
    transition('fromRight => *', slideTo('left')),
  ]);

function slideTo(direction) {
  const optional = { optional: true };
  return [
    animQuery(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        [direction]: 0,
        // width: '100%',
        height: '100%'
      })
    ], optional),
    animQuery(':enter', [
      style({ [direction]: '-100%' })
    ]),
    group([
      animQuery(':leave', [
        animate('600ms ease', style({ [direction]: '100%' }))
      ], optional),
      animQuery(':enter', [
        animate('600ms ease', style({ [direction]: '0%' }))
      ])
    ]),
    // Normalize the page style... Might not be necessary

    // Required only if you have child animations on the page
    // animQuery(':leave', animateChild()),
    // animQuery(':enter', animateChild()),
  ];
}


const query = (s, a, o = { optional: true }) => animQuery(s, a, o);

export const slider2 = trigger('routeAnimations', [
  transition('* => isLeft', rightAnimation()),
  transition('* => isRight', rightAnimation()),
  transition('isRight => *', rightAnimation()),
  transition('isLeft => *', rightAnimation()),
  transition('* => fromRight', rightAnimation()),
  transition('fromRight => *', rightAnimation()),
]);

function rightAnimation() {
  return [
    query(':enter, :leave', style({ position: 'fixed', height: '100%' })),
    query(':enter', style({ transform: 'translateX(100%)' })),

    group([
      query(':leave', [
        style({ transform: 'translateX(0%)', opacity: 0 }),
        animate('600ms ease-in-out', style({ transform: 'translateX(-100%)' }))
      ]),
      query(':enter', [
        animate('600ms ease-in-out', style({ transform: 'translateX(0%)' })),
        animateChild()
      ])
    ]),
  ];
}
// export const slider2 =
//   trigger('routeAnimations', [
//     transition('* => *', [
//       query(':enter, :leave', style({ position: 'fixed', height: '100%' })),
//       query(':enter', style({ transform: 'translateX(100%)' })),

//       group([
//         query(':leave', [
//           style({ transform: 'translateX(0%)', opacity: 0 }),
//           animate('600ms ease-in-out', style({ transform: 'translateX(-100%)' }))
//         ]),
//         query(':enter', [
//           animate('600ms ease-in-out', style({ transform: 'translateX(0%)' })),
//           animateChild()
//         ])
//       ]),
//     ]),
//   ]);

export const routerTransition = trigger('routerTransition', [
  transition('* => *', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' })),
    query(':enter', style({ transform: 'translateX(100%)' })),

    group([
      query(':leave', [
        style({ transform: 'translateX(0%)', opacity: 0 }),
        animate('600ms ease-in-out', style({ transform: 'translateX(-100%)' }))
      ]),
      query(':enter', [
        animate('600ms ease-in-out', style({ transform: 'translateX(0%)' })),
        animateChild()
      ])
    ]),
  ]),
]);

export const pageAnimation = trigger('pageAnimation', [
  transition(':enter', [
    query('h1', [
      style({ transform: 'scale(0)' }),
      animate('1s', style('*'))
    ], { optional: true })
  ]),
]);
