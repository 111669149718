import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'underscore';
import { ConfirmModalService } from '../core/confirm-modal/confirm-modal.service';
import { GlobalDataService } from '../core/services/global-data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private confirmDialogService: ConfirmModalService,
    private fb: FormBuilder,
    private gd: GlobalDataService,
    private homeService: HomeService,
  ) {
  }

  ngOnInit() {
    console.log(this.gd.userData);
    // this.getCommonData();
    // this.getMasterMenu();
  }

  getCommonData() {
    this.homeService.getCommon().subscribe(res => {
      console.log(res);
      // USER_PHOTO
    });
  }

  getMasterMenu() {
    this.homeService.getMenu().subscribe(res => {
      console.log(res);
    });
  }

}
