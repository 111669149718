import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { MatSidenavModule, MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { FrameWorkBodyService } from './frame-work-body.service';
import { Router } from '@angular/router';
import { GlobalDataService } from '../services/global-data.service';

@Component({
  selector: 'app-frame-work-body',
  templateUrl: './frame-work-body.component.html',
  styleUrls: ['./frame-work-body.component.scss']
})
export class FrameWorkBodyComponent implements OnInit {
  sideNaveMode: any = 'side';
  sidenavStatus: boolean;
  profileImage = 'assets/images/logo.png';
  // @ViewChild('sidenav', { static: false }) sidenav: MatSidenavModule;
  @ViewChild('drawer', { static: false }) drawer: MatDrawer;
  message: any;
  userdata: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 800) {
      this.sideNaveMode = 'over';
      this.sidenavStatus = false;
    } else {
      this.sideNaveMode = 'side';
      this.sidenavStatus = true;
    }
  }

  constructor(
    private router: Router,
    private frameWorkService: FrameWorkBodyService,
    private gd: GlobalDataService
  ) { }

  ngOnInit() {
    // Menu Closes when rout change
    this.router.events.subscribe(event => {
      this.drawer.close();
    });
    this.gd.USERDATA.subscribe(val => this.userdata = val);
    // this.initFrameWork();
    this.sidenavStatus = true;
    if (window.innerWidth < 800) {
      this.sideNaveMode = 'over';
      this.sidenavStatus = false;
    } else {
      this.sideNaveMode = 'side';
      this.sidenavStatus = true;
    }

  }

  receiveMessage($event) {
    console.log($event);
    // this.sidenav.toggle();
    this.drawer.toggle();
    // this.message = $event;
  }

  initFrameWork() {
    this.frameWorkService.init().subscribe(res => {
      console.log(res);
    });
  }
}
