import { CurrentUser } from 'src/app/model/current-user';
import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalDataService } from '../services/global-data.service';
import { Observable } from 'rxjs';
import { CoreService } from '../core.service';

declare const $: any;

declare var sideBtnClick: any;

@Component({
  selector: 'app-left-nav-bar',
  templateUrl: './left-nav-bar.component.html',
  styleUrls: ['./left-nav-bar.component.scss']
})

export class LeftNavBarComponent implements OnInit, OnDestroy {
  @Output() messageEvent = new EventEmitter<boolean>();
  message = 'Hola Mundo!';
  username: any;
  useremail: any;
  userdata: any;
  constructor(
    private renderer: Renderer2,
    private gd: GlobalDataService,
    private coreService: CoreService,
  ) { }
  loggedusername: string;
  currentUSer: string;

  currentUserName: string;
  loggedInUser: string;
  lastLogin: Date;
  imageselected: any;
  hosName: any;
  profilePic = './assets/images/no-image.png';

  @ViewChild('appSettings', { static: false }) appSettingsRef: ElementRef;

  // profileImage = 'assets/images/logo.png';
  // profileImage = 'assets/images/app-logo.png';

  @HostListener('window:beforeunload')
  onBeforeUnload(): Observable<boolean> | boolean {

    sessionStorage.setItem('currentUser', JSON.stringify(this.gd.currentUser));
    sessionStorage.setItem('userData', JSON.stringify(this.gd.userData));
    return true;
  }

  ngOnInit() {
    this.getCommonData();
    this.initialize();
    if (!!this.gd.userData) {
      this.userdata = this.gd.userData.user;
    }
  }

  getCommonData() {
    this.coreService.getCommon().subscribe(res => {
      // console.log(res);
      // if (!!res.data.USER_PHOTO) {

      // }
      // this.profilePic = res.data.USER_PHOTO;
      this.profilePic = res.data.USER_PHOTO || '/assets/images/no-image.png';
      this.gd.changeUserImage(this.profilePic);
    });
  }

  initialize() {
    try {
      if (sessionStorage.getItem('currentUser')) {
        const localData = sessionStorage.getItem('currentUser');

        if (!!localData) {
          this.gd.currentUser = JSON.parse(localData);
          this.gd.currentMenu = sessionStorage.getItem('currentMenu');
          this.gd.userData = JSON.parse(sessionStorage.getItem('userData'));

          this.gd.changeUserData(this.gd.userData);

          // Remove school edit details from sessionStorage
          sessionStorage.removeItem('currentUser');
          sessionStorage.removeItem('userData');

          const favIcon = document.head.querySelector('#favicon');
          // console.log(favIcon);
          // const favIconurl = this.gd.currentUser.favIcon || favIcon.getAttribute('href');
          // (favIcon as any).href = favIconurl;
          // this.profileImage = this.gd.currentUser.subOperatorLogo || 'assets/images/app-logo.png';
        }
      }

      this.currentUSer = this.gd.currentUser.name;
    } catch (e) {
    }
    if (this.gd.currentUser) {
      // this.profileImage = this.gd.currentUser.subOperatorLogo || 'assets/images/app-logo.png';
    }
    // this.loginService.getUserDetails(this.gd.currentUser.userID).subscribe(response => {
    //   // const hospitalID = response.user.hospital.id.toString();
    //   // const hospitalName = response.user.hospital.name;
    //   // this.gd.currentUser.name = response.user.name;
    //   this.gd.currentSubOpConfig = response.response;
    //   this.currentUserName = this.gd.currentSubOpConfig.name;


    //   // const hospitalAppConfigID = !!response.user.hospital.hospitalAppConfig ? response.user.hospital.hospitalAppConfig.id : null;
    //   // this.gd.currentHospital = new Hospital(hospitalName, hospitalID, hospitalAppConfigID);
    // });
  }

  navBtnClickEvent($event) {
    const st = new sideBtnClick($event);
  }

  logout() {
    const dialogOpts = { title: 'Confirm Dialog', message: 'Are you sure you want to logout ?' };
  }

  public changePass(event) {
    this.renderer.removeClass(this.appSettingsRef.nativeElement, 'active');
    this.renderer.addClass(event.target, 'active');
  }

  sendMessage() {
    this.messageEvent.emit(true);
  }

  ngOnDestroy() {
    sessionStorage.removeItem('currentMenu');
  }

}
