import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { LoginComponent } from './authentication/login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthenticComponent } from './authentic/authentic.component';
import { AuthGuardService } from './core/services/guards/auth-guard.service';
import { AuthenticDeactivateGuard } from './core/services/guards/authentic-deactivate.guard';
import { TestComponent } from './test/test.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'login', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'app', component: AuthenticComponent,
    canActivate: [AuthGuardService], canActivateChild: [AuthGuardService],
    canDeactivate: [AuthenticDeactivateGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      {
        path: 'organization',
        loadChildren: () => import('./organization-management/organization-management.module').then(m => m.OrganizationManagementModule)
      },
      { path: 'test', component: TestComponent },
    ]
  },

  { path: '401', component: UnauthorizedComponent, data: { animation: 'isRight' } },
  { path: '**', component: PageNotFoundComponent, data: { animation: 'isRight' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
