export const SYSTEM_ADMIN = ['SYSTEM_ADMIN', 'SUPER_USER'];
export const ORG_ADMIN = ['ORG_ADMIN'];
export const ORG_USER = ['ORG_USER'];
export const MANAGER = ['MANAGER'];
export const SALES_EXECUTIVE = ['SALES_EXECUTIVE'];
export const POS_USER = ['POS_USER'];





